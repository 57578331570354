<template>
  <div
    v-show="show"
    class="notification popup"
    :class="styleClass"
  >
    <button
      type="button"
      class="delete"
      @click="(e) => {
        e.stopPropagation()
        show = false
      }"
      aria-label="Close notification"
    />
    {{ popup.text }}
  </div>
</template>

<script>
import { mapState } from "vuex"

const STYLES = {
  'success': 'is-success',
  'warn': 'is-warning',
  'error': 'is-danger'
}

/** A Notification toast that shows a notification that gets set from Vuex */
export default {
  data() {
    return {
      show: false
    }
  },
  watch: {
    popup(newValue) {
      if (newValue.duration <= 0) {
        this.show = false
      } else {
        this.show = true
        setTimeout(() => {
          this.show = false
        }, this.popup.duration)
      }
    }
  },
  computed: {
    styleClass() {
      if (this.popup.style === null) {
        return ""
      } else {
        return STYLES[this.popup.style]
      }
    },
    ...mapState({
      popup: state => state.notify.popup
    }),
  }
}
</script>

<style scoped>
.popup {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}
</style>