import en from './en.json'
import nl from './nl.json'

let locale = 'nl'
let locales = { en, nl }

if (window && window.localStorage) {
  const storedLocale  = localStorage.getItem('locale')
  if (storedLocale) {
    locale = storedLocale
  }
}

export function setLocale(localeName) {
  if (locales.hasOwnProperty(localeName)) {
    locale = localeName
    localStorage.setItem('locale', locale)
    // Reload the page to apply the new locale
    // A better way would be to have `locale` as a reactive property.
    window.location.reload()
  }
}

export const availableLocales = Object.keys(locales)

export function getLocale() {
  return locale
}

export default function(identifier, data = {}) {
  // Try to find a direct exact match
  let text = locales[locale][identifier]
  if (!text) {
    // Otherwise try to find a dot separated JSON path
    const path = identifier.split('.')
    let obj = locales[locale]
    while (path.length > 0 && typeof obj === 'object') {
      obj = obj[path.shift()]
    }
    if (typeof obj !== 'string') {
      return identifier
    }
    text = obj
  }
  // Replace any {placeholder} with the corresponding value from `data`
  for (let key in data) {
    text = text.replace(`{${key}}`, data[key])
  }
  return text
}